import "../styles/styles.scss"
import { aboutPage } from "../utils/applicationText"

function About({ devonIcon }: Readonly<{ devonIcon: string }>) {

    return (
        <section className="aboutMe">
            <img alt="Devon Blue" src={devonIcon} />
            <h4>{aboutPage.desciption}</h4>
        </section>
    )
}

export default About