import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import "./styles/styles.scss"
import Header from "./components/Header";
import useFetchMultipleFolders from "./hooks/useFetchMultipleFolders";
import Gallery from "./components/Gallery";
import illstrationIcon from "./assets/images/illustration.png"
import comicsIcon from "./assets/images/comics.png"
import clothesIcon from "./assets/images/clothing.png"
import photographyIcon from "./assets/images/photography.png"
import bookCoversIcon from "./assets/images/book-covers.png"
import devonIcon from "./assets/images/devon.png"
import aboutIcon from "./assets/images/about.png"
import About from "./pages/About";


function App() {
  const location = useLocation()

  const navImages = [
    { name: "illustrations", link: illstrationIcon },
    { name: "comics", link: comicsIcon },
    { name: "clothes", link: clothesIcon },
    { name: "book-covers", link: bookCoversIcon },
    { name: "photography", link: photographyIcon },
    { name: "about", link: aboutIcon }
  ]

  const [illustrationData, illustrationLoading, illustrationError] = useFetchMultipleFolders('illustration', ['food', 'personal', 'band posters', 'children books', 'junior thesis'])

  const [comicData, comicLoading, comicError] = useFetchMultipleFolders('comics', ["bobo's cafe", "eat me", "love by lamplight", "cop-car-short", "bodega"])

  const [clothesData, clothesLoading, clothesError] = useFetchMultipleFolders('clothes', ["lookbook"])

  const [photographyData, photographyLoading, photographyError] = useFetchMultipleFolders('photography', ["photos"])

  const [bookCoversData, bookCoversLoading, bookCoversError] = useFetchMultipleFolders("book-covers", ["art"])

  return (
    <div className="app-root">
      {location.pathname !== "/" &&
        <Header navImages={navImages} />
      }
      <Routes>
        <Route path="/" element={<HomePage navImages={navImages} />} />
        <Route path="/illustrations" element={<Gallery key={"/illustration"} data={illustrationData} isLoading={illustrationLoading} error={illustrationError} />} />
        <Route path="/comics" element={<Gallery key={"/comics"} data={comicData} isLoading={comicLoading} error={comicError} />} />
        <Route path="/clothes" element={<Gallery key={"/clothes"} data={clothesData} error={clothesError} isLoading={clothesLoading} />} />
        <Route path="/photography" element={<Gallery key={"/photography"} data={photographyData} error={photographyError} isLoading={photographyLoading} />} />
        <Route path="/book-covers" element={<Gallery key={"/book-covers"} data={bookCoversData} error={bookCoversError} isLoading={bookCoversLoading} />} />
        <Route path="/about" element={<About devonIcon={devonIcon} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
