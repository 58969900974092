import { useState, useEffect } from "react";
import '../styles/styles.scss';
import { Modal, useModal } from "./Modal";
import Loader from "./Loader";
import ErrorPage from "./ErrorPage";
import { useLocation } from "react-router-dom";
import { artInformation } from "../utils/applicationText";

function Gallery({ data, isLoading, error }: Readonly<{ data: any, isLoading: boolean, error: Error | null }>) {
    //Get Data from Firestore and filters it
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState("all");

    const { isOpen, openModal, closeModal, modalIndex } = useModal()

    const location = useLocation()
    const isMulti = location.pathname === "/comics"

    useEffect(() => {
        if (data['all'])
            setFiltered(data['all'])
    }, [data, location])

    //Sets filtered data
    useEffect(() => {
        if (filtered.length > 0) {
            closeModal()
            setFiltered(data[filter]);
        }
    }, [filter])

    if (isLoading)
        return (<Loader />)
    else if (error)
        return (<ErrorPage />)
    else
        return (
            <main className="GalleryWrapper">
                {/* Filters */}
                {Object.keys(data).length > 2 && !isMulti && <div className="Filters" >
                    {Object.keys(data).sort((a, b) => a.localeCompare(b)).map(section =>
                        <h4 key={section} className={filter === section ? "SelectedFilter" : ""} onClick={() => { setFilter(section) }}>{section}</h4>
                    )}
                </div>}
                {/* Modal */}
                <Modal isOpen={isOpen} onClose={() => closeModal()}>

                    {isMulti ?
                        data[Object.keys(data)[modalIndex]]?.map((link: string) => (
                            <img
                                onClick={(e) => { e.stopPropagation() }}
                                key={link}
                                className={link}
                                src={link}
                                alt={link}
                                title={link}
                            />
                        )) :
                        <img
                            key={filtered[modalIndex]}
                            src={filtered[modalIndex]}
                            alt={filtered[modalIndex]}
                            onClick={(e) => { e.stopPropagation() }}
                        />
                    }
                </Modal>
                {/* Text */}
                {filter === "junior thesis" &&
                    <div className="Text">
                        <h4>{artInformation["Junior Thesis"]}</h4>
                    </div>
                }
                {/* Gallery */}
                <section className="Gallery">
                    {isMulti ?
                        Object.keys(data).filter((e: string) => { return e !== 'all' }).map((key: string, index: number) => {
                            return (
                                <img
                                    key={data[key][0]}
                                    className={data[key][0]}
                                    src={data[key][0]}
                                    alt={data[key][0]}
                                    title={data[key][0]}
                                    onClick={() => { openModal(index) }}
                                />
                            )
                        }) :
                        filtered.map((link: string, index: number) => (
                            <img
                                key={link}
                                className={link}
                                src={link}
                                alt={link}
                                title={link}
                                onClick={() => { openModal(index) }}
                            />
                        ))}

                </section>
            </main>
        )
}

export default Gallery;