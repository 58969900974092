const artInformation = {
    'Junior Thesis': "A collection of 6 illustrations inspired by the novel Rouge,  by Mona Awad. Focusing on the surrealistic and uneasy aspects of this Snow White retelling, these pieces draw attention to familial struggles, and the battle of one’s inner mind. This project was completed for my junior thesis at School of Visual Arts, which was then hand bound and distributed across the department.",
    'Love by Lamplight': "An illustrated poetry anthology in collaboration with the poet Mona Morsy.Love By Lamplight, the captivating first issue of The Lighthouse Chronicles series, is a unique collaboration between poet Mona Morsy and artist Devon Delcastillo. This innovative series blends the emotive power of poetry with the dynamic visual storytelling of comic books, creating an immersive artistic experience. Love By Lamplight presents a collection of four distinct poems, each brought to life through Delcastillo's stunning illustrations. These poems weave together themes of love, loss, resilience, and the quest for light amidst darkness. The lighthouse, both a physical structure and a symbolic beacon, guides readers through the emotional journey of each of the four poems, which are standalone pieces, yet together they form a cohesive narrative tapestry. Mona Morsy's vision for The Lighthouse Chronicles series is bold and innovative. By collaborating with a different artist in each issue, the series promises a diverse array of artistic interpretations that bring her poetry to life. This approach not only highlights the richness of artistic collaboration but also ensures a fresh and engaging experience in every installment." +
        <>You can check out <a href="https://www.ayoungcontrarian.com/p/love-by-lamplight-an-illustrated" target="_blank" rel="noopener">Mona’s website</a> for more information regarding the project. To purchase this zine, you can check out the following links: <a href="https://goodpress.co.uk/products/love-by-lamplight-by-mona-morsy-devon-delcastillo" target="_blank" rel="noopener">goodpress</a> | <a href="https://www.monamorsy.com/product-page/love-by-lamplight" target="_blank" rel="noopener">blog</a></>,
    'Eat Here Please!': "A riso printed zine featuring some of my favorite places to eat in Manhattan"
}

const socialMediaLinks: any = {
    'art-insta': "https://www.instagram.com/bluedelladraw/",
    'photography-insta': "https://www.instagram.com/bluedellaphoto/",
    'tiktok': "https://www.tiktok.com/@bluedelladraw",
    'email': "mailto:bluedelladraw@gmail.com"
}

const aboutPage = {
    desciption: "Devon Blue is an illustrator/cartoonist/photographer based in New York- currently studying for a BFA in illustration at the School of Visual Arts in NYC. With a love of overlaying textures and dark, bold marks, Devon strives to portray rich storytelling in each of her works. Finding inspiration in fantasy novels and song lyrics, Devon’s illustrations include a bit of the day to day lifestyle, merging into the imaginary. In her concert photography, Devon's goal is to emphasize a performer's individual story and energy through specific lighting choices, bright colors and movement." +
        "In addition, Devon manages a personal Silkscreen Business where she creates unique designs and collages, to be hand printed on textiles via a Silkscreen Press. These products are then sold through her website and in person, at local markets.",
    faqQuestions: ["How do I contact you if something is wrong with my order?", "How long does shipping take?", "How should I wash my items to ensure maximum quality?"],
    faqAnswers: [
        "Please email me at  bluedelladraw@gmail.com and include your name, order number, and reason you are contacting! all emails will be responded to on business days (monday-friday).",
        "Once you place your order, please allow up to 5 business days for your order to be shipped out!" +
        "(as I am doing this independently, I will try my best to ship out any order asap :) I will get in contact with you if there happens to be any delays)" +
        "not responsible for lost, stolen or damaged mail.Please get into contact with USPS, if your order says 'delivered' and it isn’t there." +
        "NOTE: as these are all 1 of 1 prints, returns and exchanges are not allowed. (If your item arrives damaged, get in contact with me and we can arrange something!)"
    ]
}

const shop = {
    about: "Inspired by rock concerts and the night life scenery of NYC, this collection focuses on taking the energy from those special moments and overlapping them with hand drawn illustrations. It’s a combination of my love of photography with bold linework. All designs are hand printed onto shirts, vintage slips, denim and tanks" +
        "Focusing on the act of reusing and repurposing materials, all blank slips and tees are ethically sourced/thrifted."
}

export { artInformation, socialMediaLinks, aboutPage, shop }