import React, { useEffect, useState } from 'react';
import '../styles/styles.scss';
import useDetectScroll from '@smakss/react-scroll-direction';

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalIndex, setModalIndex] = useState<number>(0);

    const openModal = (index: number) => {
        setIsOpen(true)
        setModalIndex(index)
        document.body.style.overflow = 'hidden';
    }

    const closeModal = () => {
        setIsOpen(false)
        setModalIndex(0)
        document.body.style.overflow = 'overlay';
    }

    return { isOpen, openModal, closeModal, modalIndex };
};

export function Modal(
    { isOpen, onClose, children }:
        Readonly<{ isOpen: boolean, onClose: any, children: any }>) {
    const { scrollDir, scrollPosition } = useDetectScroll();

    useEffect(() => { console.log(scrollDir) }, [scrollDir])

    if (!isOpen) return null;

    return (
        <main className="Modal" onClick={onClose}>
            <div className={"Modal Content" + (React.Children.count(children) > 1 ? " Multi" : " Single")}>
                {children}
            </div>

            <div className={"Arrow"}>
                <h3>^</h3>
            </div>
        </main>
    );
}