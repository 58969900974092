import '../styles/styles.scss';

function ErrorPage() {

    return (
        <div className="centered">
            <h1>There was an error loading the content.</h1>
        </div>
    )
}

export default ErrorPage