import { useFetchFolder } from "../hooks/useFetchFolder";
import "../styles/styles.scss"
import { socialMediaLinks } from "../utils/applicationText";

function Footer() {
    const [state, error, images] = useFetchFolder("icons/social-media")

    return (
        <section className="footer">
            {
                state === "Success" && images.map((iconInfo: any) => { return <a key={iconInfo.link} href={socialMediaLinks[iconInfo.name]} target="_blank" rel="noopener"><img src={iconInfo.link} key={iconInfo.link} alt={iconInfo.name} title={iconInfo.name} /></a> })
            }
        </section>
    )
}

export default Footer;