import { useNavigate } from "react-router-dom";
import "../styles/styles.scss"

function HomePage({ navImages }: Readonly<{ navImages: { name: string, link: string }[] }>) {
    const navigate = useNavigate()

    return (
        <main className="homePage">
            {navImages.filter(img => img.name !== "background" && img.name !== "footer").map((item) => {
                return <img
                    key={item.link}
                    className="icons"
                    src={item.link}
                    alt={item.name}
                    title={item.name}
                    onClick={() => { navigate(item.name) }}
                />
            })}
        </main>
    )
}

export default HomePage;