import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase";
import { useEffect, useState } from "react";

const useFetchMultipleFolders = (parentPath: string, folderPaths: string[]): [object, boolean, Error | null] => {
    const [folderData, setFolderData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    async function fetchFolders() {
        setIsLoading(true);
        setError(null);

        try {
            const fetchedData: any = {};

            for (const folderPath of folderPaths) {
                const folderRef = ref(storage, parentPath + "/" + folderPath);
                const folderFiles = await listAll(folderRef);

                const files: string[] = [];
                for (const file of folderFiles.items) {
                    const downloadURL = await getDownloadURL(file);
                    files.push(downloadURL);
                }

                fetchedData[folderPath] = files
            }

            // if (Object.keys(fetchedData).length > 1)
            fetchedData["all"] = Object.values(fetchedData).flat()
            setFolderData(fetchedData);
            console.log(fetchedData)
        } catch (err) {
            setError(err as Error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFolders();
    }, []);

    return [folderData, isLoading, error];
};

export default useFetchMultipleFolders;