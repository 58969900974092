import { useNavigate } from "react-router-dom";
import "../styles/styles.scss"
import { useState } from "react";
import homepageLogoWhite from "../assets/images/logoWhite.png"

function MobileNav({ navImages }: Readonly<{ navImages: { name: string, link: string }[] }>) {
    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false);
    return (
        <section className="mobileNav hideWhenBig">
            <img className="homepageLogo" title="home" alt="home" src={homepageLogoWhite} onClick={() => { navigate("/") }} />
            <h2 className="openIcon" onClick={() => setIsOpen(true)}>lIl</h2>
            {isOpen &&
                <div className="dropDownNav" onClick={() => { setIsOpen(false) }}>
                    {
                        navImages.filter(img => img.name !== "background" && img.name !== "footer").map((item) => {
                            return <img
                                key={item.link}
                                src={item.link}
                                alt={item.name}
                                title={item.name}
                                onClick={() => { navigate(item.name) }}
                            />
                        })
                    }
                    {/* <h2 className="closeIcon" onClick={() => { setIsOpen(false) }}>X</h2> */}
                </div>
            }
        </section>
    )
}

export default MobileNav;