import '../styles/styles.scss';

function Loader() {

    return (
        <div className="centered">
            <h2>Loading...</h2>
        </div>
    )
}

export default Loader