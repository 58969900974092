import { useNavigate } from "react-router-dom";
import "../styles/styles.scss"
import homepageLogo from "../assets/images/homepageLogo.png"
import MobileNav from "./mobileNav";

function Header({ navImages }: Readonly<{ navImages: { name: string, link: string }[] }>) {
    const navigate = useNavigate()

    return (
        <section className="header">
            <section className="pcNav hideWhenSmall">
                <img className="homepageLogo" title="home" alt="home" src={homepageLogo} onClick={() => { navigate("/") }} />
                <div className="nav">
                    {navImages.filter(img => img.name !== "background" && img.name !== "footer").map((item) => {
                        return <img
                            key={item.link}
                            className="headerIcons"
                            src={item.link}
                            alt={item.name}
                            title={item.name}
                            onClick={() => { navigate(item.name) }}
                        />
                    })}
                </div>
            </section>
            <MobileNav navImages={navImages} />
        </section>
    )
}

export default Header;