import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase";
import { useEffect, useState } from "react";

export const useFetchFolder = (path: string): ["Failed" | "Loading" | "Success", Error | null, { name: string, link: string }[]] => {
    const [state, setState] = useState<"Failed" | "Loading" | "Success">("Loading")
    const [imageResults, setImageResults] = useState<{ name: string, link: string }[]>([])
    const [error, setError] = useState<Error | null>(null);

    async function fetchImages() {
        try {
            const imagesRef = ref(storage, path);
            const listResult = await listAll(imagesRef);
            const imageUrls = await Promise.all(
                listResult.items.map(async (itemRef) => { return { name: itemRef.name.split(".")[0], link: await getDownloadURL(itemRef) } })
            );
            setState("Success")
            setImageResults(imageUrls)
        } catch (error) {
            setState("Failed")
            setError(error as Error)
        }
    }

    useEffect(() => {
        fetchImages()
    }, [])

    return [state, error, imageResults]
}